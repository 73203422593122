export const addDotBtnsAndClickHandlers = (emblaApi, dotsNode) => {
  	let dotNodes = [];

	const addDotBtnsWithClickHandlers = () => {
		dotsNode.innerHTML = emblaApi
			.slideNodes()
			.map(
                function(slide){
                    const dataTitle = slide.dataset.title;
                    return '<button class="embla__dot" type="button">' + dataTitle + '</button>'
                }
            )
			.join("");

		const scrollTo = (index) => {
			emblaApi.scrollTo(index);
		};

		dotNodes = Array.from(dotsNode.querySelectorAll(".embla__dot"));
		dotNodes.forEach((dotNode, index) => {
			dotNode.addEventListener("click", () => scrollTo(index), false);
		});
	};

	const toggleDotBtnsActive = () => {
		const previous = emblaApi.previousScrollSnap();
		const selected = emblaApi.selectedScrollSnap();
		dotNodes[previous].classList.remove("embla__dot--selected");
		dotNodes[selected].classList.add("embla__dot--selected");
	};

	emblaApi
		.on("init", addDotBtnsWithClickHandlers)
		.on("reInit", addDotBtnsWithClickHandlers)
		.on("init", toggleDotBtnsActive)
		.on("reInit", toggleDotBtnsActive)
		.on("select", toggleDotBtnsActive);

	return () => {
		dotsNode.innerHTML = "";
	};
};
