export const setupLazyLoadImage = (emblaApi) => {
    const imagesInView = [];
    const slideNodes = emblaApi.slideNodes();
    const spinnerNodes = slideNodes.map((slideNode) =>
        slideNode.querySelector('.loading-spinner')
    );

    const imageNodes = slideNodes.map((slideNode) =>
        slideNode.querySelector('.loading-lazy')
    );
  
    const loadImageInView = (index) => {
        const imageNode = imageNodes[index];
        if(imageNode === null) return;
        const slideNode = slideNodes[index];
        const spinnerNode = spinnerNodes[index];
        const src = imageNode.getAttribute('data-src');
  
        imageNode.src = src;
        imagesInView.push(index);
  
        const onLoad = () => {
            slideNode.classList.add('embla__lazy-load--has-loaded');
            spinnerNode.parentElement?.removeChild(spinnerNode);
            imageNode.removeEventListener('load', onLoad);
        }
        imageNode.addEventListener('load', onLoad);
    }
  
    const loadImagesInView = () => {
        emblaApi
            .slidesInView()
            .filter((index) => !imagesInView.includes(index))
            .forEach(loadImageInView);
        return imagesInView.length === imageNodes.length;
    }
  
    const loadImagesInViewAndDestroyIfDone = (emblaApi, eventName) => {
        const loadedAll = loadImagesInView();
        if (loadedAll) emblaApi.off(eventName, loadImagesInViewAndDestroyIfDone);
    }

    return loadImagesInViewAndDestroyIfDone
}
  