import { isSet } from "../utils/isSet";
import EmblaCarousel from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';
import AutoHeight from 'embla-carousel-auto-height';
import ClassNames from 'embla-carousel-class-names'
import { addPrevNextBtnsClickHandlers } from './emblaCarouselControls';
import { addDotBtnsAndClickHandlers } from './emblaCarouselPagination';
import { setupLazyLoadImage } from './emblaCarouselLazyLoad';

function initEmblaCarousels(){
    function initCardRowCarousel(){
        const cardRowCarouselNodes = document.querySelectorAll('.block-coloured-card-row .embla');
        if(!isSet(cardRowCarouselNodes)) return;

        cardRowCarouselNodes.forEach((cardRowCarouselNode) => {
            const cardRowViewportNode = cardRowCarouselNode.querySelector('.embla__viewport');
            const cardRowSlideNodes = cardRowViewportNode.querySelectorAll('.embla__slide');
            if(!isSet(cardRowViewportNode) || cardRowSlideNodes.length === 0) return;

            cardRowSlideNodes.forEach(function(cardRowSlideNode, index){
                const number = index + 1;
                const span = cardRowSlideNode.querySelector('.slide-meta-group span');
                span.dataset.number = number;
            })
    
            const cardRowOptions = {
                align: 'start',
                skipSnaps: true
            }
    
            const cardRowPrevBtnNode = cardRowCarouselNode.querySelector('.embla__button--prev');
            const cardRowNextBtnNode = cardRowCarouselNode.querySelector('.embla__button--next');
    
            const cardRowEmblaApi = EmblaCarousel(
                cardRowViewportNode, 
                cardRowOptions
            );
    
            if(isSet(cardRowPrevBtnNode) && isSet(cardRowNextBtnNode)){
                const removeCardRowPrevNextClickHandlers = addPrevNextBtnsClickHandlers(
                    cardRowEmblaApi,
                    cardRowPrevBtnNode,
                    cardRowNextBtnNode
                );
    
                cardRowEmblaApi.on('destroy', removeCardRowPrevNextClickHandlers);
            }
        });
    }
    initCardRowCarousel();


    function initTestimonialCarousel(){
        const testimonialCarouselNodes = document.querySelectorAll('.block-slider-testimonial .embla, .block-text-carousel .embla');
        if(!isSet(testimonialCarouselNodes)) return;

        testimonialCarouselNodes.forEach((testimonialCarouselNode) => {
            const testimonialViewportNode = testimonialCarouselNode.querySelector('.embla__viewport');
            const testimonialSlideNodes = testimonialViewportNode.querySelectorAll('.embla__slide');
            if(!isSet(testimonialViewportNode) || testimonialSlideNodes.length === 0) return;

            const slideCount = testimonialSlideNodes.length;
            const currentslideIndicator = testimonialViewportNode.querySelector('.page-number__current');
            const totalslideIndicator = testimonialViewportNode.querySelector('.page-number__total');
    
            const testimonialOptions = {
                align: 'start',
                dragFree: false,
                watchResize: (emblaApi, entries) => {
                    for (const entry of entries) {
                        if (emblaApi.containerNode() === entry.target) return true;
                    
                        window.requestAnimationFrame(() => {
                            emblaApi.reInit();
                            emblaApi.emit('resize'); 
                        })
                    
                        break;
                    }
                
                    return false;
                }
            }
    
            const testimonialPrevBtnNode = testimonialCarouselNode.querySelector('.embla__button--prev');
            const testimonialNextBtnNode = testimonialCarouselNode.querySelector('.embla__button--next');
    
            const testimonialEmblaApi = EmblaCarousel(
                testimonialViewportNode, 
                testimonialOptions,
                [AutoHeight()]
            );
    
            if(isSet(testimonialPrevBtnNode) && isSet(testimonialNextBtnNode)){
                const removeTestimonialPrevNextClickHandlers = addPrevNextBtnsClickHandlers(
                    testimonialEmblaApi,
                    testimonialPrevBtnNode,
                    testimonialNextBtnNode
                );
    
                testimonialEmblaApi.on('destroy', removeTestimonialPrevNextClickHandlers);
            }

            if(isSet(currentslideIndicator) && isSet(totalslideIndicator)){
                totalslideIndicator.textContent = slideCount;

                testimonialEmblaApi.on('select', function(emblaApi){
                    currentslideIndicator.textContent = emblaApi.selectedScrollSnap() + 1;
                })
            }
        });
    }
    initTestimonialCarousel();


    function initHomepageCarousel(){
        const homepageCarouselNodes = document.querySelectorAll('.block-homepage-header .embla');
        if(!isSet(homepageCarouselNodes)) return;

        homepageCarouselNodes.forEach((homepageCarouselNode) => {
            const homepageViewportNode = homepageCarouselNode.querySelector('.embla__viewport');
            const homepageSlideNodes = homepageViewportNode.querySelectorAll('.embla__slide');
            if(!isSet(homepageViewportNode) || homepageSlideNodes.length === 0) return;

            const dotsNode = homepageCarouselNode.querySelector('.embla__dots');
    
            const homepageOptions = {
                loop: true
            }
    
            const homepageEmblaApi = EmblaCarousel(
                homepageViewportNode, 
                homepageOptions,
                [
                    Autoplay(
                        {
                            delay: 7000,
                            stopOnInteraction: false,
                            stopOnMouseEnter: true
                        }
                    ),
                    ClassNames(

                    )
                ]
            );

            const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
                homepageEmblaApi,
                dotsNode
            );

            const loadImagesInView = setupLazyLoadImage(homepageEmblaApi);
            homepageEmblaApi
                .on('init', loadImagesInView)
                .on('reInit', loadImagesInView)
                .on('slidesInView', loadImagesInView)
                .on('destroy', removeDotBtnsAndClickHandlers)
                .on('autoplay:play', 
                    function(api){
                        dotsNode.classList.add('playing');
                        dotsNode.classList.remove('paused');
                    }
                )
                .on('autoplay:stop',
                    function(api){
                        dotsNode.classList.add('paused');
                        dotsNode.classList.remove('playing');
                    }
                )
        });
    }
    initHomepageCarousel();


    function initQueryCarousel(){
        const queryCarouselNodes = document.querySelectorAll('.block-post-query-loop.embla');
        if(!isSet(queryCarouselNodes)) return;

        queryCarouselNodes.forEach((queryCarouselNode) => {
            const queryViewportNode = queryCarouselNode.querySelector('.embla__viewport');
            const querySlideNodes = queryViewportNode.querySelectorAll('.embla__slide');
            if(!isSet(queryViewportNode) || querySlideNodes.length === 0) return;
    
            const queryOptions = {
                align: 'start',
                skipSnaps: true
            }
    
            const queryPrevBtnNode = queryCarouselNode.querySelector('.embla__button--prev');
            const queryNextBtnNode = queryCarouselNode.querySelector('.embla__button--next');
    
            const queryEmblaApi = EmblaCarousel(
                queryViewportNode, 
                queryOptions
            );
    
            if(isSet(queryPrevBtnNode) && isSet(queryNextBtnNode)){
                const removeQueryPrevNextClickHandlers = addPrevNextBtnsClickHandlers(
                    queryEmblaApi,
                    queryPrevBtnNode,
                    queryNextBtnNode
                );
    
                queryEmblaApi.on('destroy', removeQueryPrevNextClickHandlers);
            }
        });
    }
    initQueryCarousel();
}

exports.initEmblaCarousels = initEmblaCarousels